<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ name: 'PermConfPerm' }"><i class="el-icon-back iconBack"></i>{{$t('i18nn_c4418aa4798740f5')}}</el-breadcrumb-item>
						<el-breadcrumb-item>{{$t('i18nn_7b82edfeee93b9f7')}}</el-breadcrumb-item>
					</el-breadcrumb>
					<!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
				</el-col>
				<el-col :span="18" class="tableConTopRig">
					<!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button> -->
					<!-- <el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('ad4345dbaabe1479')}}</el-button> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- 表单提示 -->
		<!-- <tip-form-msg :formMsgDet="['可添加一个权限组，并为其命名，方便添加管理员时使用','可在标题处全选所有功能或根据功能模块逐一选择操作权限，提交保存后生效']">
		</tip-form-msg> -->
		<div class="" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
			<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px" v-loading="loading_load">
				<el-form-item label="权限组：" prop="roleName">
					<el-input type="text" v-model="form.roleName" :placeholder="$t('i18nn_886cc0a2040c65a4')"></el-input>
					
					
				</el-form-item>
				<div :style="{'height': $store.state.tableMaxHeight2+'px','overflow':'auto'}">
				<!-- <div> -->
					<el-tree :data="treeData" :default-expanded-keys="expandedKeys" :default-checked-keys="checkedKeys"
						:default-expand-all="false" show-checkbox :expand-on-click-node="false" :check-on-click-node="true"
						:check-strictly="true" node-key="menuId" ref="tree" highlight-current @check="handleCheck"
						:props="defaultProps">
					</el-tree>
				</div>
				

				<!-- <div class="menuCheckCon" v-for="(item,index) in selectOption.menuList" :label="item.menuId" :key="index" v-loading="loading_load">
          
          <div class="menuCheckL1">
            <el-checkbox-group v-model="form.menuIds" @change="handleCheckedChange($event,item,1)">
              <el-checkbox :label="item.menuId" :key="item.menuId">{{item.menuName}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="menuCheckL2">
            <el-checkbox-group v-model="form.menuIds" @change="handleCheckedChange($event,item,2)">
              <el-checkbox v-for="(item2,index) in item.menuDtoList" :label="item2.menuId" :key="index">{{item2.menuName}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div> -->
			</el-form>
			<div style="padding: 10px;">
				<el-button class="" type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('ad4345dbaabe1479')}}</el-button>
			</div>
			
		</div>
	</div>
</template>
<script>
	export default {

		// name: 'HomeConfFrame',
		// //meta信息seo用
		// metaInfo: {
		//   title: '互易天下-管理后台-基础配置'
		// },
		data() {
			return {
				// isShowFormMsg: false,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改
				// selectOption: {
				//   // deptList:[],
				//   menuList: []
				// },
				loading_load: false,
				//
				// checkedMenus:[],
				defaultProps: {
					children: 'menuDtoList',
					label: 'menuName'
				},
				treeData: [],

				expandedKeys: [],
				checkedKeys: [],
				//弹窗
				// dialogTableVisible: false,
				loading: false,
				form: {
					"roleId": null,
					"roleName": "", //权限组
					"menuIds": [] //菜单id的数组
				},
				formRules: {
					roleName: [{
							required: true,
							message: this.$t('i18nn_3f92cfa1112482ad'),
							trigger: 'blur'
						},
						// { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
					],


				},
			}
		},
		activated() {
			this.initData()
		},
		//创建时
		created() {
			// this.initData()
		},

		//编译挂载前
		mounted() {

			// console.log("mounted");

		},

		methods: {
			initData() {
				this.form.roleId = null;
				this.form.roleName = "";
				this.form.menuIds = [];
				this.checkedKeys = [];
				//部门数据
				let id = this.$route.query.id;
				if (id) {
					//修改
					this.dialogFormStatus = 1;
					this.getPremissDetData(id);
				} else {
					//新增
					this.dialogFormStatus = 0;
				}
				//菜单
				this.getMenuData();
			},
			//数组去重‘
			// getArray(a) {
			//  var hash = {},
			//      len = a.length,
			//      result = [];

			//  for (var i = 0; i < len; i++){
			//      if (!hash[a[i]]){
			//          hash[a[i]] = true;
			//          result.push(a[i]);
			//      } 
			//  }
			//  return result;
			// },
			//删除数组中存在的另一个数组
			//  bouncer(arr,un) {
			//     // var un = [false,null,0,"",undefined,NaN];
			//     for(var i = 0; i < arr.length; i++){
			//         for(var j = 0; j < un.length; j++){
			//             if(arr[i] == un[j]){
			//                 arr.splice(i,1);
			//             }
			//         }
			//     }
			//     return arr;
			// },
			//判断数组中是否存在某值
			// hasVal(arr,val){
			//   for(var i = 0; i < arr.length; i++){
			//        if(arr[i] == val ){
			//         return true;
			//        } 
			//     }
			//     return false;
			// },
			//节点选择值改变
			handleCheckChange(data, checked, indeterminate) {
				console.log(data, checked, indeterminate);
			},
			//节点选择
			handleCheck(data, checkedNode) {
				console.log(data, checkedNode);
				//一级菜单
				// if ("1" == data.leaf) {
					//是否选中
					let isChecked = checkedNode.checkedKeys.includes(data.menuId);
					console.log('isChecked', isChecked);
					//子节点IDs
					let childrenIds = [];
					
					if(data.menuDtoList){
						data.menuDtoList.map(item => {//下级
							childrenIds.push(item.menuId);
							if(item.menuDtoList){
								item.menuDtoList.map(item2 => {//下下级
									childrenIds.push(item2.menuId);
								});
							}
						});
					}
					
					//本级
					childrenIds.push(data.menuId);
					if (isChecked) { //选中
						//全部选中下面子节点
						// this.$refs.tree.setCheckedKeys(childrenIds,false);
						childrenIds.forEach(item => {
							this.$refs.tree.setChecked(item, true, true);
						});
						// this.$refs.tree.setChecked(data.menuId);
					} else { //取消选中
						//全部取消选中下面子节点
						// this.$refs.tree.setCheckedKeys(childrenIds,true);
						// this.$refs.tree.setChecked(data.menuId);
						childrenIds.forEach(item => {
							this.$refs.tree.setChecked(item, false, true);
						});
					}
				// }
			},

			//菜单选择
			// handleCheckedChange(value,item,type) {
			//   console.log(value);
			//   console.log('item',item);
			//   let menuIds = this.form.menuIds;
			//   if(item && item.menuId){
			//     if(1==type){//点击父节点
			//       if(item.menuDtoList && item.menuDtoList.length>0){//存在子节点
			//         if(this.hasVal(value,item.menuId)){//选中
			//           console.log('选中');
			//           let ar = [];
			//           for (var i = 0,ln = item.menuDtoList.length; i <ln; i++) {
			//             menuIds.push(item.menuDtoList[i].menuId);
			//           }
			//         } else {//取消选中
			//           console.log('取消选中');
			//           let childrMenuId = [];
			//           for (var i = 0,ln = item.menuDtoList.length; i <ln; i++) {
			//             childrMenuId.push(item.menuDtoList[i].menuId);
			//           }
			//           menuIds = this.bouncer(menuIds,childrMenuId);
			//         }
			//       }
			//     } else {//点击子节点
			//       menuIds.push(item.menuId);
			//     }
			//   }
			//   this.form.menuIds = this.getArray(menuIds);


			// },

			//提交信息
			submitForm(formName) {
				this.$nextTick(() => {
					this.$refs[formName].validate((valid) => {

						// if (true) {
						if (valid) {
							// alert('submit!');
							let formData = Object.assign({}, this.form);

							// formData.menuIds = this.checkedKeys;

							formData.menuIds = this.$refs.tree.getCheckedKeys();

							console.log('formData', formData);
							// return;
							// formData.menuIds = this.checkedMenus;
							//浅拷贝、对象属性的合并
							// this.form = Object.assign({},form);
							if (0 === this.dialogFormStatus) {
								formData.roleId = "";
								this.postData(this.$urlConfig.HyPermissGropAdd, formData);
							} else {
								this.postData(this.$urlConfig.HyPermissGropUpdate, formData);
							}

						} else {
							console.log('error submit!!');
							this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
							return false;
						}
					});
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				this[formName] = {};
				// console.log(this.$refs[formName]);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},
			//提交信息
			postData(url, formData) {
				let _this = this;
				this.loading = true;
				this.$http.post(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loading = false;
						if (200 == data.code) {

							this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
								type: 'success',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
							this.$router.push({
								name: 'PermConfPerm'
							});
						} else {
							this.$alert(data.msg ? data.msg : "提交失败,请重试", this.$t('cc62f4bf31d661e3'), {
								type: 'warning',
								confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
							type: 'warning',
							confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},


			// 查询菜单接口
			getMenuData() {
				let _this = this;
				// console.log(keyword);

				this.loading_load = true;
				this.$http.post(this.$urlConfig.HyMenuAllPage, {})
					.then(({
						data
					}) => {
						console.log("查询菜单接口，请求成功");
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							this.treeData = data.rows;
							//展开项，非展开项不可选择
							let expandedKeys = [];
							this.treeData.forEach(item => {
								if (item.request.indexOf('WarehouseCenter') > -1 || item.request.indexOf('PdaCenter') > -1) { //一级菜单,是仓库菜单 或PDA菜单
									expandedKeys.push(item.menuId);//一级展开
									item.menuDtoList.forEach(item2 => { //二级展开
										if(item2.menuDtoList && item2.menuDtoList.length>0){
											expandedKeys.push(item2.menuId);
										}
									})
								} else {
									item.disabled = true; //一级菜单
									item.menuDtoList.forEach(item2 => { //二级菜单
										item2.disabled = true;
									})
								}
							})
							this.expandedKeys = expandedKeys;
						} else {
							this.$message.warning(data.msg ? data.msg : "查询菜单接口失败,请重试");
						}
					})
					.catch((error) => {
						console.log(error);
						this.loading_load = false;
						console.log("查询菜单接口，请求失败");

						this.$message.error('查询菜单接口失败,请重试！');
					});
			},

			//查询详情接口
			getPremissDetData(id) {
				// let _this = this;
				this.loading = true;
				this.$http.post(this.$urlConfig.HyPermissGropDetail, {
						"roleId": id,
					})
					.then(({
						data
					}) => {
						console.log("权限组详情，请求成功");
						console.log(data);
						
						this.loading = false;
						if (200 == data.code && data.data) {
							let form = data.data;
							// form.enable = form.enable === '1' ? true : false;
							//浅拷贝、对象属性的合并
							this.form = Object.assign({}, form);

							this.checkedKeys = data.data.menuIds;
							//表格显示数据
							// this.tableData = data.rows;
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							this.$message.success('权限组详情，请求成功');
						} else {
							// if (!data.msg) {
							//   data.msg = "查询用户详情失败,请重试";
							// }
							// this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
							//   type: 'warning',
							//   confirmButtonText: this.$t('204ffab8a6e01870'),
							// });
							this.$message.warning(data.msg ? data.msg : "查询权限组详情失败,请重试");
						}

					})
					.catch((error) => {
						console.log(error);
						console.log("权限组详情，请求失败");
						this.$message.error('权限组详情，请求失败！');
						this.loading = false;
					});
			},


		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/

	// .menuCheckCon {
	//   border: 1px solid #e5e5e5;
	//   .menuCheckL1 {
	//     background-color: #f5f5f5;
	//     padding: 18px 20px;
	//     .el-checkbox {
	//       font-weight: bold;
	//       color: #222;
	//       font-size: 14px;
	//     } // border: 1px solid #e5e5e5;
	//   }
	//   .menuCheckL2 {
	//     margin: 0 18px;
	//     .el-checkbox {
	//       margin-left: 0;
	//       padding: 18px;
	//       color: #222;
	//       font-size: 14px;
	//       border: 1px solid #e5e5e5;
	//       border-right: 0; // border-top:0;
	//     } // border: 1px solid #e5e5e5;
	//   }
	// }
</style>
